var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.attachmentData.length > 0)?_c('section',{attrs:{"id":"collapse-container"}},[_c('b-collapse',{staticClass:"card",attrs:{"animation":"slide","aria-id":"collapse-container","open":true},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return [_c('div',{attrs:{"id":"collapse-container-title","role":"button","aria-controls":"collapse-container","aria-expanded":props.open}},[_c('p',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.isFreeFinding ? 'FREE FINDING' : 'ATTACHMENTS')+" ("),_c('span',[_vm._v(_vm._s(_vm.totalNumberOfImages.total)+" pictures ")]),(_vm.totalNumberOfImages.excluded > 0)?_c('span',[_vm._v("   / "+_vm._s(_vm.totalNumberOfImages.excluded)+" excluded ")]):_vm._e(),_vm._v(") ")]),_c('a',{staticClass:"card-header-icon"},[_c('b-icon',{attrs:{"icon":props.open ? 'menu-down' : 'menu-up'}})],1)])]}}],null,false,2232143234)},[(_vm.attachmentData.length > 0)?_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},_vm._l((_vm.attachmentData),function(att,index){return _c('div',{staticClass:"content-attchment"},[(att.question)?_c('div',{staticClass:"title-question"},[_vm._v(" Question: "+_vm._s(att.question)+" ")]):_vm._e(),(att.label)?_c('div',{staticClass:"label-images"},[_vm._v(" Label "+_vm._s(att.label)+" ")]):_vm._e(),(att.description)?_c('div',{staticClass:"title-comment"},[_vm._v(" Description: "+_vm._s(att.description)+" ")]):_vm._e(),(att.comment)?_c('div',{staticClass:"title-comment"},[_vm._v(" "+_vm._s(att.comment)+" ")]):_vm._e(),_vm._l((att.images),function(image,i){return _c('div',{staticClass:"content-attchment"},[_c('div',{class:i == att.images.length - 1
                  ? ' container-image underline-border'
                  : 'container-image'},[_c('div',{class:_vm.isImageExclude(image.url || image.path || image.src)
                    ? 'element excluded'
                    : 'element'},[(_vm.enableExcludeItems)?_c('b-checkbox',{attrs:{"value":_vm.isImageExclude(image.url || image.path || image.src)},on:{"input":(e) =>
                      _vm.onImageToExclude(
                        e,
                        image.url || image.path || image.src
                      )}}):_vm._e(),_c('span',{staticClass:"description-name"},[_vm._v("Name:")]),_vm._v(" "+_vm._s(image.name)+" "),_c('span',{on:{"click":function($event){return _vm.editImageName(image, att)}}},[_c('b-icon',{staticClass:"edit-icon",attrs:{"custom-size":"mdi-24px","icon":"pencil","type":"is-info"}})],1)],1),_c('div',{staticClass:"element"},[_c('span',{staticClass:"description-name"},[_vm._v("Description:")]),(image.description)?_c('span',[_vm._v(_vm._s(image.description))]):_vm._e(),_c('span',{on:{"click":function($event){return _vm.editImageDescription(image, att)}}},[_c('b-icon',{staticClass:"edit-icon",attrs:{"custom-size":"mdi-24px","icon":"pencil","type":"is-info"}})],1)]),_c('ImageWithErrorFallback',{attrs:{"imageUrl":_vm.getImageUrl(image),"excluded":_vm.isImageExclude(image?.src || image?.url || image?.path)},on:{"click":function($event){return _vm.onImageClick(image?.src || image?.url || image?.path)}}})],1)])})],2)}),0)]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }